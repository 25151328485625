var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":`定时任务${_vm.isUpdate ? '编辑' : '创建'}`,"visible":_vm.visible,"width":800},on:{"close":_vm.reset}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128,"disabled":_vm.isUpdate},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"props":"settings"}},[_c('question-icon',{attrs:{"slot":"label","title":"资源配置","description":"默认统计全部资源"},slot:"label"}),_vm._l((_vm.form.settings),function(setting,index){return _c('div',{key:`setting_${index}`,staticStyle:{"display":"flex"}},[_c('a-form-model-item',{staticStyle:{"margin-right":"16px"},attrs:{"prop":`settings[${index}].source_type`,"rules":[
            {
              message: '请选择资源类型',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.sourceType},model:{value:(setting.source_type),callback:function ($$v) {_vm.$set(setting, "source_type", $$v)},expression:"setting.source_type"}})],1),_c('a-form-model-item',{attrs:{"prop":`settings[${index}].source_target`,"rules":[
            {
              message: '请选择资源范围',
              required: true,
              trigger: 'change'
            }
          ]}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"options":_vm.options.sourceTarget},model:{value:(setting.source_target),callback:function ($$v) {_vm.$set(setting, "source_target", $$v)},expression:"setting.source_target"}})],1),(setting.source_type)?[(setting.source_target === 'group')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`settings[${index}].groups`,"rules":[
              {
                message:
                  '请选择' + _vm.$t(`source_type.${setting.source_type}`) + '群组',
                required: true,
                trigger: 'change'
              }
            ]}},[_c('group-select',{staticStyle:{"width":"236px"},attrs:{"source-type":setting.source_type},model:{value:(setting.groups),callback:function ($$v) {_vm.$set(setting, "groups", $$v)},expression:"setting.groups"}})],1):(setting.source_target === 'selected')?_c('a-form-model-item',{staticStyle:{"margin-left":"16px"},attrs:{"prop":`settings[${index}].sources`,"rules":[
              {
                message: '请选择' + _vm.$t(`source_type.${setting.source_type}`),
                required: true,
                trigger: 'change'
              }
            ]}},[_c('multi-source-select',{staticStyle:{"width":"236px"},attrs:{"source-type":setting.source_type},model:{value:(setting.sources),callback:function ($$v) {_vm.$set(setting, "sources", $$v)},expression:"setting.sources"}})],1):_vm._e()]:_vm._e(),_c('a-form-model-item',{staticStyle:{"margin-left":"16px"}},[_c('a-button',{attrs:{"icon":"minus-circle","type":"link"},on:{"click":function($event){return _vm.form.settings.splice(index, 1)}}})],1)],2)}),_c('a-button',{attrs:{"block":true,"icon":"plus","type":"dashed"},on:{"click":function($event){return _vm.form.settings.push({
            sourceType: undefined,
            sourceTarget: undefined,
            sources: undefined,
            groups: undefined
          })}}},[_vm._v(" 添加资源 ")])],2),_c('a-form-model-item',{attrs:{"label":"任务类型","prop":"cronTypes"}},[_c('a-checkbox-group',{model:{value:(_vm.form.cronTypes),callback:function ($$v) {_vm.$set(_vm.form, "cronTypes", $$v)},expression:"form.cronTypes"}},[_c('a-checkbox',{attrs:{"value":"daily"}},[_vm._v("每日")]),_c('a-checkbox',{attrs:{"value":"weekly"}},[_vm._v("每周")]),_c('a-checkbox',{attrs:{"value":"monthly"}},[_vm._v("每月")])],1)],1),_c('a-form-model-item',{attrs:{"label":"状态","prop":"active"}},[_c('a-switch',{model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }