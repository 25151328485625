<template>
  <a-drawer
    :destroy-on-close="true"
    :title="`定时任务${isUpdate ? '编辑' : '创建'}`"
    :visible="visible"
    :width="800"
    @close="reset"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128" :disabled="isUpdate"></a-input>
      </a-form-model-item>
      <a-form-model-item props="settings">
        <question-icon
          slot="label"
          title="资源配置"
          description="默认统计全部资源"
        ></question-icon>
        <div
          v-for="(setting, index) in form.settings"
          :key="`setting_${index}`"
          style="display: flex"
        >
          <a-form-model-item
            :prop="`settings[${index}].source_type`"
            :rules="[
              {
                message: '请选择资源类型',
                required: true,
                trigger: 'change'
              }
            ]"
            style="margin-right: 16px"
          >
            <a-select
              v-model="setting.source_type"
              :options="options.sourceType"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            :prop="`settings[${index}].source_target`"
            :rules="[
              {
                message: '请选择资源范围',
                required: true,
                trigger: 'change'
              }
            ]"
          >
            <a-select
              v-model="setting.source_target"
              :options="options.sourceTarget"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <template v-if="setting.source_type">
            <a-form-model-item
              v-if="setting.source_target === 'group'"
              :prop="`settings[${index}].groups`"
              :rules="[
                {
                  message:
                    '请选择' + $t(`source_type.${setting.source_type}`) + '群组',
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <group-select
                v-model="setting.groups"
                :source-type="setting.source_type"
                style="width: 236px"
              ></group-select>
            </a-form-model-item>
            <a-form-model-item
              v-else-if="setting.source_target === 'selected'"
              :prop="`settings[${index}].sources`"
              :rules="[
                {
                  message: '请选择' + $t(`source_type.${setting.source_type}`),
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px"
            >
              <multi-source-select
                v-model="setting.sources"
                :source-type="setting.source_type"
                style="width: 236px"
              ></multi-source-select>
            </a-form-model-item>
          </template>
          <a-form-model-item style="margin-left: 16px">
            <a-button
              icon="minus-circle"
              type="link"
              @click="form.settings.splice(index, 1)"
            ></a-button>
          </a-form-model-item>
        </div>
        <a-button
          :block="true"
          icon="plus"
          type="dashed"
          @click="
            form.settings.push({
              sourceType: undefined,
              sourceTarget: undefined,
              sources: undefined,
              groups: undefined
            })
          "
        >
          添加资源
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="任务类型" prop="cronTypes">
        <a-checkbox-group v-model="form.cronTypes">
          <a-checkbox value="daily">每日</a-checkbox>
          <a-checkbox value="weekly">每周</a-checkbox>
          <a-checkbox value="monthly">每月</a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="reset">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { createSourceTaskReport, getSourceTaskReport, updateSourceTaskReport } from '@/api/report'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'
import { sourceTargets, sourceTypes } from '@/utils/const'

export default {
  name: 'TaskInput',
  components: {
    GroupSelect,
    MultiSourceSelect,
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      form: {
        id: '',
        name: '',
        settings: [],
        active: false,
        cronTypes: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        cronTypes: [
          {
            message: '请选择任务类型',
            required: true,
            trigger: 'change'
          }
        ]
      },
      options: {
        sourceType: sourceTypes.filter(item => {
          if (item !== 'application' && item !== 'site') { return item }
        }).map(value => {
          return {
            title: this.$t(`source_type.${value}`),
            value
          }
        }),
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        })
      },
      loading: false,
      isUpdate: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getSourceTaskReport(id).then(res => {
          const data = res.data
          this.form = {
            id,
            name: data.name,
            settings: data.settings,
            active: data.active,
            cronTypes: data.cron_types
          }
        }).finally(() => { this.visible = true })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    reset () {
      this.visible = false
      this.isUpdate = false
      this.form = {
        name: '',
        settings: [],
        active: false,
        cronTypes: undefined
      }
      this.$refs.form.resetFields()
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            active: this.form.active,
            cron_types: this.form.cronTypes
          }
          if (this.form.settings.length !== 0) {
            params.settings = this.form.settings.map(setting => {
              const data = {
                source_type: setting.source_type,
                source_target: setting.source_target
              }
              if (setting.source_target === 'group') {
                data.group_ids = setting.groups.map(group => group.id)
              } else if (setting.source_target === 'selected') {
                data.source_ids = setting.sources.map(source => source.id)
              }
              return data
            })
          }
          if (this.isUpdate) {
            updateSourceTaskReport(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.name = this.form.name
            createSourceTaskReport(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.reset()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
