<template>
  <div>
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['reload', 'search', 'leftTool']"
        :refresh="refresh"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
        :table-operation-width="200"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            @click="$refs.inputDrawer.show()"
          >
          </create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip v-if="slotProps.text" :title="slotProps.text">{{ slotProps.text }}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template #cronTypes="{ slotProps }">
          <a-tag v-for="text in slotProps.text" :key="text" :color="getCronColor(text)">{{ $t(`cron_type.${text}`) }}</a-tag>
        </template>
        <template #active="{ slotProps }">
          <a-switch :checked="slotProps.text"  @change="v => changeStatus(v, slotProps.record)" :loading="checkedDisabled"></a-switch>
        </template>
        <template #operation="{ slotProps }">
          <edit-button
          @click="$refs.inputDrawer.show(slotProps.record.id)"
        >
        </edit-button>
          <delete-button
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>

      <task-input ref="inputDrawer" @ok="confirm"></task-input>
  </div>
</template>

<script>
import { getSourceTaskReportList, deleteSourceTaskReport, updateSourceTaskReport } from '@/api/report'
import DeleteButton from '@/components/button/DeleteButton'
import TaskInput from './TaskInput.vue'
import CreateButton from '@/components/button/CreateButton'
import EditButton from '@/components/button/EditButton'

export default {
  name: 'TaskTable',
  components: {
    DeleteButton,
    ListLayout: () => import('@/components/ListLayout'),
    TaskInput,
    EditButton,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getSourceTaskReportList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 140,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'cron_types',
          title: '任务类型',
          width: 180,
          scopedSlots: {
            customRender: 'cronTypes'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        },
        {
          dataIndex: 'active',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'active'
          }
        }
      ],
      columnDefaultSelected: [
        'name',
        'active',
        'created_at',
        'cron_types',
        'updated_at'],
      refresh: false,
      tableSelectData: [],
      checkedDisabled: false
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteSourceTaskReport(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    },
    changeStatus (active, item) {
      this.checkedDisabled = true
      updateSourceTaskReport(item.id, { active }).then(res => {
        this.$message.success(res.message)
        this.refresh = !this.refresh
      }).finally(() => {
        this.checkedDisabled = false
      })
    },
    getCronColor (cron) {
      if (cron === 'daily') return 'cyan'
      if (cron === 'weekly') return 'blue'
      return 'purple'
    }
  }
}
</script>

<style>

</style>
